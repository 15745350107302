import {SyntheticEvent, useEffect, useState} from "react";
import FocusedInput, {InputProps} from "./FocusedInput";


export type SelectProps<T> = {
    fetcher: (term: string) => Promise<T[]>,
    label?: (v: T) => string,
    customValues?: boolean,
    required?: boolean,
    value?: T,
    onChange?: (e: SyntheticEvent<HTMLInputElement>, v: T) => void
    valid?: boolean
} & Omit<Omit<InputProps, "value">, "onChange">

export default function SearchableSelect<T>({
                                                fetcher,
                                                customValues = false,
                                                onChange,
                                                label = v => v?.toString(),
                                                required,
                                                value,
                                                valid = true,
                                                ...props
                                            }: SelectProps<T>) {

    let [term, setTerm] = useState("")
    const [values, setValues] = useState<T[]>([])

    // @ts-ignore
    term = customValues ? term : (value ? label(value) : term);

    useEffect(() => {
        const load = async () => {
            const values = await fetcher(term);
            setValues(values);
        }
        load()
    }, [term]);

    const update = (e: SyntheticEvent<HTMLInputElement>, v: string) => {
        if (customValues || values.map(label).includes(v))
            onChange(e, values.find(e => label(e) === v))
        if (!customValues)
            setTerm(v)
    }

    const id = Math.random().toString();
    return <>
        <FocusedInput {...props} value={term} onChange={update} list={id} required={required}
                      valid={valid && (customValues || values.map(label).includes(term))}/>
        <datalist id={id}>
            {values.map((v) => <option key={JSON.stringify(v)} value={label(v)}/>)}
        </datalist>

    </>
}
