import {PageFooter, usePageable} from "../../component/Pageable";
import {getRepositories, Repository} from "../../data/Repository";
import RepositoryCard from "../../component/RepositoryCard";
import {getProjects, Project} from "../../data/Project";
import ProjectCard from "../../component/ProjectCard";
import {$CollapseArrow, $Section, $SectionBody, $SectionHeader} from "./Dashboard.style";
import {storageKey, useStorage} from "../../common/useStorage";

const HIDE_REPOSITORIES = storageKey("hide-repositories", false)

const fetchRepositories = ({index, size}): Promise<Repository[]> => {
    return getRepositories({page: index, size, query: "mock == true"});
}

export default function Dashboard() {

    const repositoryPager = usePageable(fetchRepositories, 50, 4000)
    const [hideRepositories, setHideRepositories] = useStorage(HIDE_REPOSITORIES)

    return <>
        <h1>Dashboard</h1>

        <$Section>
            <$SectionHeader>
                <h2>Requested Repositories</h2>
                <$CollapseArrow onClick={() => setHideRepositories(!hideRepositories)}
                                $collapsed={hideRepositories}></$CollapseArrow>
            </$SectionHeader>
            <$SectionBody $collapsed={hideRepositories}>
                {repositoryPager.records.map(r => <RepositoryCard key={r.key} record={r}/>)}
                <PageFooter {...repositoryPager.footerProps}/>
            </$SectionBody>
        </$Section>
    </>
}