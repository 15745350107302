import styled, {css} from "styled-components";
import {SlArrowUp} from "react-icons/sl";


export const $Section = styled.div`
    padding: 2em 0;
`

export const $SectionHeader = styled.div`
    display: grid;
    grid-template-columns:  1fr auto;
    align-items: center;
`

export const $SectionBody = styled.div<{ $collapsed: boolean }>`
    transition: max-height 0.5s;
    max-height: 1000vh;
    overflow: hidden;
    ${p => p.$collapsed && css`
        max-height: 0;
    `}

`

export const $CollapseArrow = styled(SlArrowUp)<{ $collapsed: boolean }>`
    transition: transform 0.5s;
    ${p => p.$collapsed && css`
        transform: rotate(180deg);
    `}
`