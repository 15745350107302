import styled from "styled-components";



export const $Container = styled.div`

  & .spinner {
    width: 48px;
    height: 48px;
    border: 5px solid ${props => props.theme.activeColor};
    border-bottom-color: ${props => props.theme.primaryColor};
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  & .paused {

  }

  & .end {
    text-align: center;
  }

  & .page {

  }
  
`