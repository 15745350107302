import {$Ball, $Collapse, $Container, $Footer, $NavGroup, $NavItem, $NavItemLink, $Title} from "./Nav.style";

import {NavLink} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {storageKey, useStorage} from "../common/useStorage";
import Icons from "./Icons";

// @ts-ignore
export const VERSION = process.env.VERSION;

export const NAV_COLLAPSED = storageKey("nav-collapsed", false)


export default function Nav() {

    const [collapsed, setCollapsed] = useStorage(NAV_COLLAPSED);

    if (collapsed === null) return;

    return <$Container>

        <$Title $collapsed={collapsed}>Artifactory</$Title>

        <$Ball onClick={() => setCollapsed(!collapsed)} $collapsed={collapsed}>
            <IoArrowBack/>
        </$Ball>
        <$Collapse $collapsed={collapsed}>
            <$NavGroup>

                <NavLink to={"dashboard"}>
                    {({isActive}) => (
                        <$NavItem $active={isActive}>
                            <Icons.Dashboard/>
                            <$NavItemLink>Dashboard</$NavItemLink>
                        </$NavItem>
                    )}
                </NavLink>

                <NavLink to={"repositories"}>
                    {({isActive}) => (
                        <$NavItem $active={isActive}>
                            <Icons.Repository/>
                            <$NavItemLink>Repositories</$NavItemLink>
                        </$NavItem>
                    )}
                </NavLink>

                {/*<NavLink to={"transactions"}>*/}
                {/*    {({isActive}) => (*/}
                {/*        <$NavItem $active={isActive}>*/}
                {/*            <List/>*/}
                {/*            <$NavItemLink>Transactions</$NavItemLink>*/}
                {/*        </$NavItem>*/}
                {/*    )}*/}
                {/*</NavLink>*/}

                {/*<NavLink to={"schedule"}>*/}
                {/*    {({isActive}) => (*/}
                {/*        <$NavItem $active={isActive}>*/}
                {/*            <EventRepeat/>*/}
                {/*            <$NavItemLink>Schedule</$NavItemLink>*/}
                {/*        </$NavItem>*/}
                {/*    )}*/}
                {/*</NavLink>*/}

                {/*<NavLink to={"tags"}>*/}
                {/*    {({isActive}) => (*/}
                {/*        <$NavItem $active={isActive}>*/}
                {/*            <LabelOutlined/>*/}
                {/*            <$NavItemLink>Tags</$NavItemLink>*/}
                {/*        </$NavItem>*/}
                {/*    )}*/}
                {/*</NavLink>*/}

                {/*<NavLink to={"pools"}>*/}
                {/*    {({isActive}) => (*/}
                {/*        <$NavItem $active={isActive}>*/}
                {/*            <SavingsOutlined/>*/}
                {/*            <$NavItemLink>Pools</$NavItemLink>*/}
                {/*        </$NavItem>*/}
                {/*    )}*/}
                {/*</NavLink>*/}

                {/*<NavLink to={"settings"}>*/}
                {/*    {({isActive}) => (*/}
                {/*        <$NavItem $active={isActive}>*/}
                {/*            <TuneOutlined/>*/}
                {/*            <$NavItemLink>Settings</$NavItemLink>*/}
                {/*        </$NavItem>*/}
                {/*    )}*/}
                {/*</NavLink>*/}

            </$NavGroup>

            <$Footer>
                v{VERSION}
            </$Footer>
        </$Collapse>

    </$Container>
}