import {storageKey, useStorage} from "./useStorage";
import {useEffect} from "react";
import {getUser, User} from "../data/User";

// @ts-ignore
const AUTH_URI = process.env.AUTH_URI;

export type Session = {
    expiration: number,
    user: User
}

export type Auth = {
    session: Session,
    setSession: (session: Session) => void,
    signOut: () => void
    signIn: () => void
    isAuthenticated: boolean
}

const SESSION = storageKey<Session>("session", undefined)

export const useAuth = (): Auth => {
    const [session, setSession] = useStorage<Session>(SESSION);

    return {
        session,
        setSession,
        signOut: () => setSession(undefined),
        signIn: () => window.location.href = AUTH_URI,
        isAuthenticated: session && session.expiration > new Date().getTime(),
    }
}

export default function Authenticated({children}) {

    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated) return
        const load = async () => {
            try {
                const user = await getUser()
                auth.setSession({expiration: new Date().getTime() + 60000, user})
            } catch (e) {
                if (e.status == 401) {
                    auth.signIn();
                } else throw e;
            }
        }
        load()
    }, [auth]);

    return <>{auth.isAuthenticated && children}</>
}

