import {getJson} from "../common/http";

export type Project = {
    id: number

    name: string;
    url: string;
    updatedAt: string;
}

export function getProjects({page = 0, size = 50, name = "", repositoryType = ""}): Promise<Project[]> {
    return getJson("/projects", {page, size, name, repositoryType})
}

export function getProjectById(id: number): Promise<Project> {
    return getJson(`/projects/${id}`)
}

