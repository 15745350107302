import {DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState} from "react";
import {$Container} from "./Pageable.style";
import {RecordList, useRecords} from "../common/useRecords";
import {MdMotionPhotosOn} from "react-icons/md";

export type Page = {
    index: number
    size: number
    position: number
}

type PagerState = "PAUSED" | "LOADING" | "END"

const isOnScreen = (e: any) => {
    if (!e) return;
    const rect = e.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 500);
}

type PageableProps<T> = {
    footerProps: PageFooterProps,
    reload: () => void
} & RecordList<T>

export const usePageable = <T, >(
    fetchRecords: (p: Page) => Promise<T[]>,
    pageSize: number = 50,
    fetchInterval: number = 1000
): PageableProps<T> => {

    const records = useRecords<T>();

    const [state, setState] = useState<PagerState>("LOADING");
    const [schedule, setSchedule] = useState<number>(0);
    const ref = useRef();

    const doFetch = async () => {
        const pageIndex = Math.ceil((records.values.length /*- 1*/) / 50)
        const newRecords = await fetchRecords({index: pageIndex, size: pageSize, position: pageIndex * pageSize});
        records.addAll(newRecords)
        return newRecords.length >= pageSize
    }

    useEffect(() => {
        (async () => {
            if (state === "END") return
            if (state === "PAUSED" || !isOnScreen(ref.current) || await doFetch()) {
                setTimeout(() => setSchedule(schedule + 1), fetchInterval);
            } else {
                setState("END")
            }
        })()
    }, [schedule, state]);

    const reload = () => {
        records.clear()
        setState("LOADING")
    }

    return {
        ...records,
        footerProps: {state, setState, innerRef: ref, empty: records.values.length == 0},
        reload
    }
}

type PageFooterProps = {
    state: PagerState
    setState: (state: PagerState) => void
    innerRef: any,
    empty: boolean
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export function PageFooter({state, setState, innerRef, empty, ...props}: PageFooterProps) {
    return <$Container ref={innerRef} {...props}>
        {state === "LOADING" &&
            <span className="spinner" onClick={() => setState("PAUSED")}></span>}
        {state === "PAUSED" &&
            <MdMotionPhotosOn className="paused" onClick={() => setState("LOADING")}/>}
        {state === "END" && !empty &&
            <p className={"end"} onClick={() => setState("LOADING")}>You have reached the end</p>}
        {state === "END" && empty &&
            <p className={"end"} onClick={() => setState("LOADING")}>No results found</p>}
    </$Container>
}
