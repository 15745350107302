import {MdAdd, MdAssignmentAdd, MdDelete, MdEdit} from "react-icons/md";
import styled, {css} from "styled-components";
import {SiGitlab} from "react-icons/si";
import {RiGitRepositoryLine} from "react-icons/ri";
import {IoMdList} from "react-icons/io";


const style = (c: any) => styled(c)<{ disabled: boolean, onClick: any, size: string }>`
    ${p => p.disabled && css`
        color: ${p => p.theme.inactiveColor};
    `}
    ${p => !p.disabled && p.onClick && css`
        &:hover {
            color: ${p => p.theme.accentColor};
        }
    `}
    font-size: ${props => props.size || "1em"};
`

const Icons = {
    Delete: style(MdDelete),
    Add: style(MdAdd),
    Edit: style(MdEdit),
    AddToList: style(MdAssignmentAdd),
    GitLab: style(SiGitlab),
    Repository: style(RiGitRepositoryLine),
    Dashboard: style(IoMdList)
};

export default Icons;
