import styled from "styled-components";

export const $Form = styled.form`
    background-color: ${props => props.theme.secondaryColor};
    margin: 1em 0;

    display: grid;
    border: none;
    padding: 1em;
    gap: 1em;
    
    & > input, & > select {
        margin-bottom: 1em;
    }
`


export const $FromFooter = styled.div`
    display: flex;
    justify-content: space-between;
`
