import {PageFooter, usePageable} from "../../component/Pageable";
import {getRepositories, Repository} from "../../data/Repository";
import RepositoryCard from "../../component/RepositoryCard";
import Fab from "../../component/Fab";
import Icons from "../../component/Icons";


const fetchRepositories = ({index, size}): Promise<Repository[]> => {
    return getRepositories({page: index, size});
}


export default function Repositories() {

    const repositoryPager = usePageable<Repository>(fetchRepositories, 50, 4000)


    return <>
        <h1>Repositories</h1>
        {repositoryPager.records.map(r => <RepositoryCard key={r.value.id} record={r}/>)}
        <PageFooter {...repositoryPager.footerProps}/>

        <Fab to="/repositories/create">
            <Icons.Add></Icons.Add>
        </Fab>
    </>

}