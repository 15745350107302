import {$Fab} from "./Fab.style";
import {ButtonHTMLAttributes, DetailedHTMLProps} from "react";
import {Link, LinkProps} from "react-router-dom";


type FabProps =
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> | LinkProps

export default function Fab({
                                children,
                                ...props
                            }: FabProps) {
    return <$Fab {...props} as={props.to ? Link : "button"}>
        {children}
    </$Fab>
}