import styled, {css} from "styled-components";

const invalidStyle = css`
    border: none;
    background-color: ${pros => pros.theme.activeColor};
    color: ${pros => pros.theme.primaryColor};
`

const validStyle = css`
    border: none;
    background-color: inherit;
    color: ${pros => pros.theme.activeColor};
`

export const $Select = styled.select<{ $valid?: boolean }>`
    ${validStyle}
    ${props => props.$valid === false && invalidStyle}
    &:focus, &:invalid {
        ${invalidStyle}
    }
    &[type=number] {
        -moz-appearance: textfield !important;
    }
`
