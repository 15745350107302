//@ts-ignore
export const API_URI = process.env.API_URI;
//@ts-ignore
export const HEADERS = JSON.parse(process.env.REQUEST_HEADERS || "{}")


type param = string | number
type params = { [param: string]: param }

export const getText = async (url: string, params?: params): Promise<string> => {
    return get(url, params).then(r => r.text());
};

export const getJson = async <T>(url: string, params?: params): Promise<T> => {
    return get(url, params).then(r => r.json());
};

export const postJson = async <T>(url: string, body?: any): Promise<T> => {
    return post(url, body).then(r => r.json());
};

export const putJson = async <T>(url: string, body?: any): Promise<T> => {
    return put(url, body).then(r => r.json());
};

export const patchJson = async <T>(url: string, body?: any): Promise<T> => {
    return patch(url, body).then(r => r.json());
};

export const deleteJson = async <T>(url: string, params?: params): Promise<T> => {
    return _delete(url, params).then(r => r.json());
};

export const post = async (url: string, body?: any): Promise<Response> => {
    return execFetch(url, undefined, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {"Content-Type": "application/json"}
    });
};

export const get = async (url: string, params?: params): Promise<Response> => {
    return execFetch(url, params);
};

export const put = async (url: string, body?: any): Promise<Response> => {
    return execFetch(url, undefined, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {"Content-Type": "application/json"}
    });
};

export const patch = async (url: string, body?: any): Promise<Response> => {
    return execFetch(url, undefined, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {"Content-Type": "application/json"}
    });
};

export const _delete = async (url: string, params?: params): Promise<Response> => {
    return execFetch(url, params, {
        method: "DELETE",
        headers: {"Content-Type": "application/json"}
    });
};

export const execFetch = async (url: string, params?: params, body?: RequestInit): Promise<Response> => {
    try {
        const response = await fetch(concatParams(API_URI + url, params), {
            ...body,
            credentials: "include",
            headers: {...body?.headers, ...HEADERS}
        });
        if (!response.ok)
            return Promise.reject(response);
        return new Promise((res, rej) => res(response));
    } catch (e) {
        return Promise.reject(e);
    }
};

const concatParams = (url: string, params?: params): string => {
    if (!params) return url;
    let query: string = Object.entries(params).map(([k, v]) => encodeURIComponent(k) + "=" + encodeURIComponent(v)).join("&") || "";
    if (url.includes("?"))
        return url + "&" + query;
    return url + "?" + query;
};

