import {_delete, getJson, postJson, putJson} from "../common/http";

export type Repository = {
    id: number

    name: string;
    browseURI?: string;
    projectId?: number;
    type: RepositoryType;
    mock: boolean;

    group?: string; // For maven repositories
}

export type RepositoryType = "MAVEN" | "GCODE_KIT" | "OTHER"
export const REPOSITORY_TYPES: RepositoryType[] = ["MAVEN", "GCODE_KIT"]

export function getRepositories({page = 0, size = 50, query = ""}): Promise<Repository[]> {
    return getJson("/repositories", {page, size, query})
}

export function getRepositoryById(id: number): Promise<Repository> {
    return getJson(`/repositories/${id}`)
}

export function deleteRepository(repositoryId: number): Promise<Response> {
    return _delete(`/repositories/${repositoryId}`)
}

export function createRepository(repository: Partial<Repository>): Promise<Repository> {
    return postJson(`/repositories`, repository)
}

export function updateRepository(repository: Partial<Repository>): Promise<Repository> {
    return putJson(`/repositories/${repository.id}`, repository)
}
