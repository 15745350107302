import {
    ChangeEvent,
    DetailedHTMLProps,
    OptionHTMLAttributes,
    ReactElement,
    SelectHTMLAttributes,
    useEffect,
    useState
} from "react";
import {$Select} from "./FocusedSelect.style";


export type SelectProps<T> = {
    children: ReactElement<OptionProps<T>, typeof Option<T>>[]
    onChange?: (e: ChangeEvent<HTMLSelectElement>, v: T) => void
    required?: boolean
    value?: string,
    valid?: boolean
} & Omit<Omit<DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, "onUpdate">, "onChange">

export default function FocusedSelect<T>({
                                             onChange,
                                             value,
                                             required = true,
                                             children,
                                             valid = true,
                                             ...props
                                         }: SelectProps<T>) {

    const [realValue, setRealValue] = useState<string>(value);

    useEffect(() => {
        setRealValue(value)
    }, [value])

    const options = children ? children.flatMap(e => Array.isArray(e) ? e : [e]) : [];
    const optionsByName = Object.fromEntries(options.map(o => [o.props?.children, o.props?.value]));
    const selectedValue = options.find(o => o.props.children == realValue)?.props.children || ""

    const change = (e: ChangeEvent<HTMLSelectElement>) => {
        const key = e.target.options[e.target.selectedIndex].value;
        if (e.target.checkValidity() && key !== realValue)
            onChange && onChange(e, optionsByName[key])
        !onChange && setRealValue(key)
    }

    return <$Select {...props} required={required} value={selectedValue} onChange={change} $valid={valid && (!required || selectedValue !== "")}>
        {required &&
            <option style={{display: "none"}} disabled={true} value={""}>-</option>}
        {!required &&
            <option>-</option>}
        {options}
    </$Select>
}

export type OptionProps<T> = {
    value: T,
    children: string,
    disabled?: boolean,
} & Omit<DetailedHTMLProps<OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>, "value">

export function Option<T>({value, children, disabled, ...props}: OptionProps<T>) {
    return <option {...props} {...(disabled ? {disabled: true, style: {display: "none"}} : {})}
                   data-value={value} value={children}>{children}</option>
}
