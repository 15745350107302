import styled from "styled-components";


export const $Container = styled.div`
  background-color: ${props => props.theme.secondaryColor};
  padding: 1.5em;
  position: sticky;
  top: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.75);
`

export const $Title = styled.h1<{ $collapsed?: boolean }>`
  color: ${props => props.theme.activeColor};
  text-align: left;
  max-width: 100vh;
  overflow: hidden;

  @media only screen and (min-width: 1000px) {
    ${props => props.$collapsed && `
    max-width: 0;
  `};
  }
  transition: max-width 0.5s;
`

export const $Ball = styled.div<{ $collapsed?: boolean }>`
  background-color: ${props => props.theme.accentColor};
  color: ${props => props.theme.activeColor};
  text-align: left;
  width: 2em;
  height: 2em;
  border-radius: 1em;

  display: grid;
  justify-content: center;
  align-content: center;

  position: absolute;
  bottom: -1em;
  right: 1em;

  transform: rotate(90deg);

  ${props => props.$collapsed && `
    transform: rotate(270deg);
  `};

  @media only screen and (min-width: 1000px) {
    bottom: initial;
    right: -1em;
    top: 1em;
    transform: rotate(0deg);

    ${props => props.$collapsed && `
      transform: rotate(-180deg);
  `};
  }

  transition: transform 0.5s;
`

export const $Collapse = styled.div<{ $collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  max-height: 100vh;
  max-width: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    ${props => props.$collapsed && `
    max-height: 0;
  `};
  }
  @media only screen and (min-width: 1000px) {
    ${props => props.$collapsed && `
    max-width: 0;
  `};
  }
  transition: max-height 0.5s, max-width 0.5s;
`

export const $NavGroup = styled.nav`
  padding: 0.5em 0;
  width: 10em;
`

export const $NavItem = styled.div<{ $active?: boolean }>`
  color: ${props => props.$active ? props.theme.activeColor : props.theme.inactiveColor};
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 0.25em 0;
  padding: 0.25em 0;

  ${props => props.$active && `
    padding-left: 1em;
    border-left-style: solid;
    border-left-width: 2px;
    border-left-color: ${props.theme.accentColor};
  `};

  transition: padding-left 0.5s;
`

export const $NavItemLink = styled.p`

`

export const $Footer = styled.div`
  border-top-color: ${props => props.theme.inactiveColor};
  color: ${props => props.theme.inactiveColor};

  margin-top: auto;
  border-top-style: solid;
  border-top-width: 2px;
  padding-top: 1em;
  text-align: right;
`

