import {Repository} from "../data/Repository";
import {$Card, $CardHeader, $CardOptions} from "./Card.style";
import Icons from "./Icons";
import {RecordValue} from "../common/useRecords";
import {Link} from "react-router-dom";

// @ts-ignore
const BROWSE_URL_PREFIX = process.env.BROWSE_URL_PREFIX;

export default function RepositoryCard({record}: { record: RecordValue<Repository> }) {
    return <$Card>
        <$CardHeader>
            <h3>{record.value.name} ({record.value.type})</h3>
            {record.value.browseURI && <a target="_blank" href={`${BROWSE_URL_PREFIX}${record.value.browseURI}`}>
                <Icons.Repository title="Browse"/>
            </a>}
        </$CardHeader>

        <$CardOptions>
            {
                record.value.mock
                    ? <Link to={`/repositories/edit/${record.value.id}`}>
                        <Icons.AddToList title={"Assign to a project"}/>
                    </Link>
                    : <Link to={`/repositories/edit/${record.value.id}`}>
                        <Icons.Edit title={"Edit"}/>
                    </Link>
            }
        </$CardOptions>

    </$Card>

}