import {DetailedHTMLProps, InputHTMLAttributes, SyntheticEvent, useEffect, useState} from "react";
import {$Input} from "./FocusedInput.style";


export type InputProps = {
    onChange?: (e: SyntheticEvent<HTMLInputElement>, v: string) => void
    value: string,
    valid?: boolean
    required?: boolean
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange">

export default function FocusedInput({onChange, value, valid = true, required = false, ...props}: InputProps) {

    let [realValue, setRealValue] = useState(value);

    useEffect(() => {
        setRealValue(value)
    }, [value])

    const update = (e: SyntheticEvent<HTMLInputElement>) => {
        if (e.currentTarget.checkValidity() && value !== realValue)
            onChange(e, realValue)
    }

    return <$Input {...props}
                   required={required}
                   value={realValue}
                   $valid={valid && (!required || realValue.trim().length > 0)}
                   onChange={e => setRealValue(e.target.value)}
                   onBlur={update}
                   onAbort={update}
                   onKeyDown={e => (e.key === "Escape" || e.key == "Enter") && update(e)}/>
}