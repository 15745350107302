import {atom, PrimitiveAtom, useAtom} from "jotai";
import {useEffect} from "react";

type StorageKey<T> = {
    name: string,
    atom: PrimitiveAtom<T>
    default: T

    loader: Loader<T>
    saver: Saver<T>
}

type Loader<T> = (key: StorageKey<T>) => Promise<T | null>
type Saver<T> = (key: StorageKey<T>, value: T | null) => void

const LOCAL_STORAGE_PREFIX = "storage."

export const localStore = <T>(): { loader: Loader<T>, saver: Saver<T> } => {
    return {
        loader: (key: StorageKey<T>): Promise<T | null> => {
            const json = localStorage.getItem(LOCAL_STORAGE_PREFIX + key.name);
            if (json === null) return Promise.resolve(null);
            try {
                return Promise.resolve(JSON.parse(json))
            } catch (e) {
                localStorage.removeItem(LOCAL_STORAGE_PREFIX + key.name)
                return Promise.resolve(null);
            }
        },
        saver: (key, value) => {
            if (value === null) localStorage.removeItem(LOCAL_STORAGE_PREFIX + key.name)
            else localStorage.setItem(LOCAL_STORAGE_PREFIX + key.name, JSON.stringify(value))
        }
    }
}

export function storageKey<T>(name: string, value: T | undefined, store: {
    loader: Loader<T>,
    saver: Saver<T>
} = localStore()): StorageKey<T> {
    return {name, atom: atom<T>(null as T), default: value, ...store}
}

export function useStorage<T>(key: StorageKey<T>): [T, (value: T) => void] {

    let [state, setState] = useAtom(key.atom)

    useEffect(() => {
        (async () => {
            if (state === null) {
                const item = await key.loader(key);
                setState(item === null ? key.default : item)
            } else {
                key.saver(key, state)
            }
        })();
    }, [state]);

    return [state, setState]
}










