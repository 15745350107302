import styled from "styled-components";

export const $Container = styled.div`
  color: ${props => props.theme.activeColor};
  background-color: ${props => props.theme.primaryColor};

  height: 100dvh;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1000px) {
    display: flex;
    flex-direction: row;
  }
`

export const $Content = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
`

export const $Main = styled.main`
  padding: 3em 2em 1em 2em;
  flex-grow: 2;
`
