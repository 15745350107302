// @ts-nocheck

toastr.options.closeButton = false;
toastr.options.debug = false;
toastr.options.newestOnTop = true;
toastr.options.progressBar = true;
toastr.options.positionClass = "toast-top-right";
toastr.options.preventDuplicates = false;
toastr.options.showDuration = 0;
toastr.options.hideDuration = 0;
toastr.options.extendedTimeOut = 3000;
toastr.options.showEasing = "swing";
toastr.options.hideEasing = "linear";
toastr.options.showMethod = "fadeIn";


const toaster = {
    error: (message: string, err?: any) => {
        toastr.error(message);
        err && console.error(message + ": " + err);
    },
    success: (message: string) => toastr.success(message),
    warning: (message: string, err?: any) => {
        toastr.warning(message);
        err && console.log(message + ": " + err);
    },
};

export default toaster;