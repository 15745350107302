import styled from "styled-components";


export const $Card = styled.div`
    margin: 1em 0;
    padding: 1em;
    background-color: ${p => p.theme.secondaryColor};

    display: grid;
    grid-template-columns: 1fr auto;
`


export const $CardOptions = styled.div`
    display: flex;
    justify-items: end;
    gap: 0.5em;
`

export const $CardHeader = styled.div`
    display: flex;
    align-items: end;
    gap: 0.5em;
`