import styled, {css} from "styled-components";


export const $Fab = styled.button<{ onClick: any, to: any, disabled: boolean }>`
    background-color: ${props => props.theme.accentColor};
    position: absolute;
    border-radius: 1.5em;
    width: 3em;
    height: 3em;
    bottom: 2em;
    right: 2em;

    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;

    ${p => p.disabled && css`
        color: ${p => p.theme.inactiveColor};
    `}
    ${p => !p.disabled && (p.onClick || p.to) && css`
        &:active {
            filter: brightness(1.25);
        }
    `}
`