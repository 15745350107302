import {Route as ReactRoute, Routes} from "react-router-dom";
import Authenticated from "./common/useAuth";

export type Route = {
    page: any,
    path: string
}

export type RouteConfig = {
    routes: {
        page: any,
        path: string
    }[],
}

export function useRoute(routes: Route[]): RouteConfig {
    return {routes}
}

export default function Route(props: { route: RouteConfig }) {

    return (
        <Routes>
            {props.route.routes.map(r => <ReactRoute key={r.path} path={r.path} element={
                <Authenticated>
                    <r.page/>
                </Authenticated>
            }/>)}
        </Routes>
    );
}
