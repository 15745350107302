import {useEffect, useState} from "react";
import {
    createRepository,
    deleteRepository,
    getRepositoryById,
    Repository,
    REPOSITORY_TYPES,
    RepositoryType,
    updateRepository
} from "../../data/Repository";
import {getProjectById, getProjects, Project} from "../../data/Project";
import SearchableSelect from "../../component/SearchableSelect";
import FocusedInput from "../../component/FocusedInput";
import FocusedSelect, {Option} from "../../component/FocusedSelect";
import {$Form, $FromFooter} from "./RepositoryForm.style";
import {useNavigate, useParams} from "react-router-dom";
import toaster from "../../common/toaster";


const fetchProjects = (name: string): Promise<Project[]> => {
    return getProjects({name});
}


export default function RepositoryForm() {

    const [repository, setRepository] = useState<Partial<Repository>>({})
    const [project, setProject] = useState<Project>()

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) return;
        const load = async () => {
            const repository = await getRepositoryById(Number(id));
            setRepository(repository);
            if (repository.projectId) {
                const project = await getProjectById(repository.projectId);
                setProject(project);
            }
        }
        load()
    }, [id]);

    const submit = async (e) => {
        e.preventDefault()

        try {
            const data = {
                ...repository,
                projectId: project.id,
            }
            if (repository.id) {
                await updateRepository(data)
                toaster.success("Repository updated")
            } else {
                const newRepository = await createRepository(data)
                toaster.success("Repository created")
                navigate(`/repositories/edit/${newRepository.id}`)
            }
        } catch (e) {
            console.error(e)
            toaster.error("Could not create repository")
        }
    }

    const _delete = async () => {
        try {
            if (!confirm("Do your really want to delete this repository?"))
                return
            await deleteRepository(repository.id)
            toaster.success("Repository successfully deleted")
            navigate("/repositories")
        } catch (e) {
            toaster.error("Error deleting repository")
        }
    }

    return <div>
        <h1>Create Repository</h1>

        <$Form onSubmit={submit}>
            <label>Repository Name</label>
            <FocusedInput value={repository.name || ""} required={true}
                          onChange={(e, v) => setRepository({...repository, name: v})}/>

            <label>Repository Type</label>
            <FocusedSelect<RepositoryType> value={repository.type} required={true}
                                           onChange={(e, v) => setRepository({...repository, type: v})}>
                {REPOSITORY_TYPES.map(t => <Option key={t} value={t}>{t}</Option>)}
            </FocusedSelect>

            <label>Project</label>
            <SearchableSelect<Project> fetcher={fetchProjects} value={project} required={true}
                                       onChange={(e, v) => setProject(v)}
                                       label={v => v.name}/>

            {repository.type === "MAVEN" && <>
                <label>Group</label>
                <FocusedInput value={repository.group || ""} required={true}
                              onChange={(e, v) => setRepository({
                                  ...repository,
                                  group: v
                              })}/>
            </>
            }

            <$FromFooter>
                {repository.id && <button onClick={_delete} type="button">Delete</button>}
                <button type="submit">Save</button>
            </$FromFooter>
        </$Form>


    </div>

}