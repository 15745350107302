import {useState} from "react";

export type RecordList<T> = {
    update: (records: T[]) => void
    clear: () => void
    add: (record: T) => void,
    addAll: (record: T[]) => void,
    values: T[]
    records: RecordValue<T>[]
}

export type RecordValue<T> = {
    update: (record: T) => void
    remove: () => void
    value: T
    key: any
}

export const useRecord = <T>(value?: T, props?: { keyExtractor: (r: T) => any }): RecordValue<T> => {
    // @ts-ignore
    const keyExtractor = props?.keyExtractor ? props.keyExtractor : (r: T) => r.id;

    const [record, setRecord] = useState(value)
    return {
        update: setRecord,
        remove: () => setRecord(undefined),
        value: record,
        key: keyExtractor(record),
    }
}

export const useRecords = <T>(values: T[] = [], props?: {
    sort: (t1: T, t2: T) => number,
    keyExtractor: (r: T) => any
}): RecordList<T> => {
    // @ts-ignore
    const keyExtractor = props?.keyExtractor ? props.keyExtractor : (r: T) => r.id;

    const [records, setRecords] = useState<T[]>(values)
    const sorted = props?.sort ? records.sort(props.sort) : records;

    return {
        update: setRecords,
        clear: () => setRecords([]),
        add: r => setRecords([r, ...records]),
        addAll: rs => setRecords([...rs, ...records]),
        values: sorted,
        records: sorted.map(r => listRecord(r, records, setRecords, keyExtractor))
    }
}


const listRecord = <T>(record: T, records: T[], setRecords: (records: T[]) => void, keyExtractor: (r: T) => any): RecordValue<T> => {
    return {
        update: newR => setRecords(records.map(r => keyExtractor(record) === keyExtractor(r) ? newR : r)),
        remove: () => setRecords(records.filter(r => keyExtractor(record) !== keyExtractor(r))),
        value: record,
        key: keyExtractor(record)
    }
}


















