import {BrowserRouter} from "react-router-dom";
import Route, {useRoute} from "./Route";
import {ThemeProvider} from "styled-components";
import Dashboard from "./page/dashboard/Dashboard";
import {$Container, $Content, $Main} from "./App.style";
import Nav from "./component/Nav";
import Footer from "./component/Footer";
import Repositories from "./page/repositories/Repositories";
import useAuth from "./common/useAuth";
import RepositoryForm from "./page/repositories/RepositoryForm";

// @ts-ignore
export const UI_URL_PREFIX = process.env.UI_URL_PREFIX;


const theme = {
    secondaryColor: "#252422",
    primaryColor: "#403D39",
    accentColor: "#8800ff",
    activeColor: "#eaeaea",
    inactiveColor: "#bdbdbd",
};


export default function App() {

    const routes = useRoute([
        {path: "/", page: Dashboard},
        {path: "/dashboard", page: Dashboard},
        {path: "/repositories", page: Repositories},
        {path: "/repositories/create", page: RepositoryForm},
        {path: "/repositories/edit/:id", page: RepositoryForm},
        // {path: "/transactions", page: Transactions},
        // {path: "/schedule", page: Dashboard},
        // {path: "/tags", page: Dashboard},
        // {path: "/pools", page: Dashboard},
        // {path: "/settings", page: Settings},
        // {path: "/dashboard", page: Dashboard},
        // {path: "/dashboard/edit", page: DashboardCellForm},
        // {path: "/transactions", page: Transactions},
        // {path: "/tags", page: Tags},
        // {path: "/pools", page: Pools},
        // {path: "/synthesise", page: Synthesise},
        // {path: "/settings", page: Settings},
    ])

    return <BrowserRouter basename={UI_URL_PREFIX}>
        <ThemeProvider theme={theme}>
            <$Container>
                <Nav/>
                <$Content>
                    <$Main>
                        <Route route={routes}/>
                    </$Main>
                    <Footer/>
                </$Content>
            </$Container>
        </ThemeProvider>
    </BrowserRouter>;
}